@import "./variables";
@import "./typography";

ps-input-validation{
  display: flex;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  flex-wrap: wrap;
  & > label{
    @extend .col-form-label;
  }
}

.validation-container{
  position: absolute;
  top: 27px;
  right: 1.5rem;
  z-index: 102;
}

.ps-hint{
  position: absolute;
  color: #989898;
  font-size: .75rem;
  right: 16px;
}

.validation-errors-container{
  position: absolute;
  top: 37px;
  max-width: 90%;
  opacity: 0;
  z-index: -1;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 8px 7px;
    border-color: transparent transparent $danger-color transparent;
    position: relative;
    left: 12px;
  }

  &.show{
    opacity: 1;
    z-index: 2000;
  }
}

.validation-errors {
  position: relative;
  border: 1px solid $danger-color;
  background-color: $danger-color;
  list-style: none;
  margin: 0 0 0 0;
  padding: .25rem;
  z-index: 1040;
  transition: $default-transition;
  top: 6px;

  & li {
    white-space: nowrap;
    color: #ffffff;
  }
}
