﻿$fontPath: "/fonts";

@font-face {
  font-family: 'Gilroy Extra Bold';
  src: url('#{$fontPath}/Gilroy-ExtraBold-webfont.eot');
  src: url('#{$fontPath}/Gilroy-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fontPath}/Gilroy-ExtraBold-webfont.woff') format('woff'),
       url('#{$fontPath}/Gilroy-ExtraBold-webfont.ttf') format('truetype'),
       url('#{$fontPath}/Gilroy-ExtraBold-webfont.svg#gilroyextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Semi Bold';
  src: url('#{$fontPath}/Gilroy-SemiBold-webfont.eot');
  src: url('#{$fontPath}/Gilroy-SemiBold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fontPath}/Gilroy-SemiBold-webfont.woff') format('woff'),
       url('#{$fontPath}/Gilroy-SemiBold-webfont.ttf') format('truetype'),
       url('#{$fontPath}/Gilroy-SemiBold-webfont.svg#gilroysemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy Light';
  src: url('#{$fontPath}/Gilroy-Light-webfont.eot');
  src: url('#{$fontPath}/Gilroy-Light-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fontPath}/Gilroy-Light-webfont.woff') format('woff'),
       url('#{$fontPath}/Gilroy-Light-webfont.ttf') format('truetype'),
       url('#{$fontPath}/Gilroy-Light-webfont.svg#gilroylight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sabon LT Pro Roman';
  src: url('#{$fontPath}/SabonLTPro-Roman-webfont.eot');
  src: url('#{$fontPath}/SabonLTPro-Roman-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fontPath}/SabonLTPro-Roman-webfont.woff') format('woff'),
       url('#{$fontPath}/SabonLTPro-Roman-webfont.ttf') format('truetype'),
       url('#{$fontPath}/SabonLTPro-Roman-webfont.svg#sabonltproroman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sabon LT Pro Bold';
  src: url('#{$fontPath}/SabonLTPro-Bold-webfont.eot');
  src: url('#{$fontPath}/SabonLTPro-Bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$fontPath}/SabonLTPro-Bold-webfont.woff') format('woff'),
       url('#{$fontPath}/SabonLTPro-Bold-webfont.ttf') format('truetype'),
       url('#{$fontPath}/SabonLTPro-Bold-webfont.svg#sabonltprobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

$default-color: #333F48;
$disable-color: lighten($default-color, 40%);
$primary-color: #7F9C90;
$success-color: #333F48;
$warning-color: #bdbf2b;
$danger-color: #9A2323;
$body-bg-color: #fbfbfb;
$default-font-family: "Gilroy Light";
$default-font-size: 14px;
$main-nav-font-size: 16px;
$main-open-nav-width: 264px;
$main-nav-padding: 12px 20px 13px 20px;
$default-font-color: #333F48;
$default-bg-color: #ebebeb;
$sub-nav-bg-color: $default-bg-color;
$border-color: #e1e1e1;
$main-nav-link-color: $success-color;
$main-nav-link-hover-color: #ebebeb;
$global-nav-height: 50px;
$action-bar-height: 40px;
$default-border: 1px solid $border-color;
$default-transition: 200ms ease-out;
$secondary-color: #003C71;

$chapman-red: #9A2323;
$chapman-navy: #003C71;
$chapman-green: #286140;
$chapman-lightgreen: #7F9C90;
$chapman-orange: #FF9E1B;
$chapman-slate: #333F48;
$chapman-grey: #8E9B97;


