// Customize the look and feel of the grid with Sass variables
// Up-to-date list of variables is available in the source code: https://github.com/ag-grid/ag-grid/blob/latest/src/styles/theme-fresh.scss 
//$icons-path: "~ag-grid/src/styles/icons/";

// changes the border color
// $border-color: #FF0000;

// Changes the font size
// $font-size: 12px;

// Changes the font family
// $font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

// Reverts the cell horizontal padding change between ag-fresh and ag-theme-fresh
$cell-horizontal-padding: 2px;

// changes the icon color
// $icon-color: red;
// $primary-color: green;
.ag-theme-material {
    --ag-header-cell-hover-background-color:#1A2024;
}

.ag-theme-material .ag-row-level-1 .ag-row-group-expanded {
    background-color: #eee
}

.ag-theme-material .ag-row-level-1{
    background-color: #eee;
    white-space: normal;
}

.ag-theme-material .ag-row-hover {
    color: #3e79a7;
}

.ag-theme-material .ag-row .ag-row-level-0 .ag-row-odd {
    background-color:  #333F48;
}

.ag-theme-material .ag-tab-selected {
    border-bottom-color: #333F48 !important;
  }

  .ag-theme-material input[class^=ag-]:focus:not([type]),
.ag-theme-material input[class^=ag-][type=text]:focus,
.ag-theme-material input[class^=ag-][type=number]:focus,
.ag-theme-material input[class^=ag-][type=tel]:focus,
.ag-theme-material input[class^=ag-][type=date]:focus,
.ag-theme-material input[class^=ag-][type=datetime-local]:focus,
.ag-theme-material textarea[class^=ag-]:focus {
    border-bottom-color: #333F48 !important;
}

.ag-theme-material .ag-header-cell {
    font-family: 'Gilroy Semi Bold', sans-serif;
    background-color: #333F48;
    color: #fff!important;
    font-size: 13px;
    line-height: normal;
    transition: .2s ease-out;
    padding: 0;
    &:hover {
        text-decoration: none;
        background-color: darken(#333F48, 10%);
    }
}

.gridFooter{
    width: 280px;
    //top: -40px;
    bottom: 10px;
    position: absolute;//relative;
    left: 40px;
}

.popover {
    width:calc(100% - 307px);
    left: calc(50% - 176px) !important;
    top: 20px !important;
    max-width: calc(100% - 264px) !important;
}

.ag-header-cell-text{
    padding: .5rem;
}
.ag-icon{
    font-size: 10px;
}

.ag-theme-material .ag-header-icon{
    //padding-top: 10px;
    padding-right: 6px;
    color:#fff;
}

//.ag-cell-label-container:hover{
    .ag-theme-material .ag-header-cell .ag-focus-managed .ag-column-hover .ag-header-active{
    text-decoration: none;
    background-color: #333F48;
}

//ag-header-icon ag-header-cell-menu-button
.ag-theme-material .ag-cell {
    font-family: 'Gilroy Light', sans-serif; /* Fallback to sans-serif */
    padding: .75rem;
    font-size: 13px;
    line-height: normal;
}

// .shaded-row{
//     background-color: #efefef;
// }

.tooltip-inner {
    min-width: 100px;
    max-width: 500px;
}