﻿@import "variables";

.btn{
  border-radius: 0;
  font-size: 14px;
  cursor: pointer;
  font-family: "Gilroy Semi Bold", sans-serif !important;
}

.btn-primary{
  background-color: $chapman-lightgreen;
  border: 1px solid $chapman-lightgreen;

  &:hover{
    background-color: darken($chapman-lightgreen, 50%);
  }
}

.btn-secondary{
  background-color: $chapman-navy;
  border: 1px solid $chapman-navy;

  &:hover{
    background-color: darken($chapman-navy, 50%);
  }
}

.btn-confidential{
  background-color: $chapman-orange;
  border: 1px solid $chapman-orange;

  &:hover{
    background-color: darken($chapman-orange, 50%);
  }
}

.btn-cancel{
  background-color: $chapman-red;
  border: 1px solid $chapman-red;

  &:hover{
    background-color: darken($chapman-red, 50%);
  }
}

.btn-primary-clean{
  color: $primary-color;
  border: none;
  background-color: transparent;
  font-weight: 600;

  &:hover{
    background-color: $primary-color;
    color: #fff;
  }
}

.btn-success{
  background-color: $success-color;
  border: 1px solid $success-color;

  &:hover{
    background-color: darken($success-color, 10%);
  }
}

.btn-default{
  background-color: $chapman-slate;
  border: 1px solid $chapman-slate;
  color: #fff;
  &:hover{
    background-color: darken($chapman-slate, 10%);
    color: #fff;
  }
}

.btn-user{
  &:hover{
    color: lighten($default-color, 50%);
    background-color: darken($default-color, 10%);
  }
  cursor: pointer;
}

.btn-link{
  color: $primary-color
}

.btn-remove{
  color: $danger-color;
}

.form-control{
  border-radius: 0;
}




