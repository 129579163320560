@import "./variables";

.card {
  margin-bottom: .85rem;
  border-radius: 0;

  & .card-header {
    background-color: $success-color;
    color: #ffffff;
    border-radius: 0;
  }

  & .card-accent {
    font-weight: 800;
    font-size: 3.5rem;
    position: absolute;
    opacity: .18;
    right: 20px;
    bottom: -5px;
  }
}

.form-control{
  font-size: $default-font-size;
  position: relative;
  z-index: 101;
  -webkit-appearance: auto; 
  appearance: auto;
}

.col-form-label,
.form-control-label {
  font-size: .85rem;
  margin-bottom: 0px;
  padding-top: 0;
  padding-bottom: 0;
  color: #333F48;
}

.form-check-label{
  color: $default-font-color;
}

.jump-to {
  position: fixed;
  right: 0;
  top: 128px;
  width: 175px;

  & .jump-to-list {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    list-style: none;

    & > li > a {
      color: lighten(#333F48, 15%);
    }

    & > li.has-danger > a{
      color: $danger-color;
    }
  }
}

.has-danger {
  & .form-control-label{
    color: $danger-color !important;
  }
  & .col-form-label{
    color: $danger-color;
  }
}

.static-text {
  margin-bottom: .5rem;
  color: #000000;
}

.content-affixed-right {
  margin-right: calc(175px + 8px);
}

@media (max-width: 767px) {
  .content-affixed-right {
    margin-right: 0;
  }
}

.inline-input-group{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.inline-input{
  border: $default-border;
  display: flex;
  margin: 0 0 0 0;
  padding: .30rem .75rem;
  font-size: 14px;
  flex-grow: 1;
  line-height: 1.25;
}

.quick-filter{
  display: inline-block;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  border: $default-border;
  background: #ffffff;
  position: relative;
  width: 100%;

  input{
    border: none;
    padding: .5rem 2rem .5rem .75rem;
    width: 100%;
    font-size: 14px;
    line-height: 1.25;
  }

  i.fa{
    position: absolute;
    padding: .5rem 0;
    right: .75rem;
    top: 0;
    color: #c0c0c0;
  }
}

.form-check-container{
  display: block;
  width: 100%;
}

.custom-control-input:disabled~.custom-control-indicator {
  cursor: not-allowed;
  background-color: lighten(#0275d8, 35%) !important;
}


