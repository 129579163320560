@import "variables";

.alert {
  border-radius: 0;
}

.alert-overlay {
  z-index: 9999;
  position: fixed;
  top: calc(#{$global-nav-height} + #{$action-bar-height});
  width: calc(100% - #{$main-open-nav-width});
  left: $main-open-nav-width;

  & .alert {
    box-shadow: 2px 5px 5px 0px rgba(0,0,0,0.2);
  }
}

//.alert-success {
//  background-color: #286916;
//  border-color: #286916;
//  color: #ffffff;
//}
