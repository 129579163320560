@import "@angular/material/prebuilt-themes/indigo-pink";
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-material";
@import "../../node_modules/bootstrap/dist/css/bootstrap.min";
@import "../../node_modules/font-awesome/css/font-awesome";
@import "loading";
@import "variables";
@import "buttons";
@import "nav";
@import "typography";
@import "validation";
@import "form";
@import "alerts";
@import "grid";

html {
  height: 100%;
}

body {
  font-size: $default-font-size;
  color: $default-font-color !important;
  background-color: #f9f9f9;
  height: 100%;
  font-family:  "Gilroy Light", sans-serif !important;
}

a {
  color: $secondary-color;
}

:root {
  --bs-danger-rgb: 154, 35, 35; /* Corresponds to #9A2323 */
  --bs-success-rgb: 127 156 144 /* Corresponds to #7F9C90 */
  ---bs-primary-rgb: 51 63 72 /* Corresponds to #333F4 */
}

.tooltip-inner {
  background-color: #333F48 !important;
}

.modal-footer-left {
  justify-content: flex-start;
}

.btn-cancel {
  color: #eee !important;
}

.btn-primary {
  background-color: #7F9C90 !important;
  border-color: #7F9C90 !important;
}

.hierarchy-node-text.selected{
  background-color:  #8E9B97 !important;
}

.btn-outline-success{
  --bs-btn-color: #7F9C90 !important;
  --bs-btn-border-color: #7F9C90 !important;
  --bs-btn-hover-bg: #7F9C90 !important;
}

.btn-outline-danger {
  --bs-btn-color: #9A2323 !important;
  --bs-btn-border-color: #9A2323 !important;
  --bs-btn-hover-bg: #9A2323 !important;
}

.form-check-input:checked {
  background-color: #333F48 !important;
  border-color: #333F48 !important;
}

.text-success{
  color: #7F9C90 !important;
}

.form-check-input {
 position: relative;
}

:not(.mat-option-multiple):not(.mat-option-disabled).mat-option.mat-selected{
  background: #9b9b9b
}

:not(.mat-option-disabled).mat-option:focus, :not(.mat-option-disabled).mat-option:hover{
  background: #9b9b9b
}

.mat-option.mat-active{
  background: #9b9b9b
}

.site-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.table-title {
  font-size: 32px;
  font-family: 'Gilroy Light';
  display: inline-block;
  line-height: 32px;
}

button, input, optgroup, select, textarea {
  margin: 0 0 0 4px;
}

.content-wrapper {
  order: 1;
  margin-left: calc(#{$main-open-nav-width});
  width: calc(100% - #{$main-open-nav-width});
  height: 100%; //calc(100% - (#{$global-nav-height} + #{$action-bar-height} + 25px));
  //margin-top: calc(#{$global-nav-height} + #{$action-bar-height});
  & > header {
    border-bottom: $default-border;
    position: fixed;
    top: 0px;//calc(-#{$global-nav-height} - #{$action-bar-height});
    width: 100%;//calc(100% - #{$main-open-nav-width});
    background-color: #fff;
    height: $global-nav-height;
    z-index: 100;
  }
}

.content {
  position: absolute;
  top: calc(#{$global-nav-height} + #{$action-bar-height});
  height: calc(100% - (#{$global-nav-height} + #{$action-bar-height} + 25px));
  width: calc(100% - #{$main-open-nav-width});
  z-index: 0;
  //padding-top: 12px;
  //padding-bottom: 12px;
  margin-top:12px;
  margin-bottom:12px;
  //height: 100%;
}

#GridWithH1{
  height: calc(100% - 32px); //This 32px accounts for the div that shares the parent (and takes up 32px) - We need 100% for scaling.
}

.modal-grid-dialog {
  height: 90%;
  width: 90%;
  max-width: 90%;
}

.modal-content {
  height: 100%;
  width: 100%;
  }

.aniborder
{
  color: $primary-color;
  font-weight: bolder;

  &:hover{
    color: #9A2323;
    transition:all 0.5s ease;
  }
}

.card .card-header{
  background-color: $success-color;
}

.select-list {
  width: 100%;
}

.select-list-striped {
  .select-list-item:nth-child(even) {
    background-color: #ebebeb;
  }
}

.select-list-item {
  padding: .25rem .75rem;
  width: 100%;
  color: #000;

  & .item-icon {
    opacity: 0;
    transition: 100ms ease-out;
  }

  &.clickable {
    cursor: pointer;
    transition: 100ms ease-out;
    user-select: none;

    &.selected {
      background-color: $primary-color;
      color: #fff;
      &:hover{
        background-color: lighten($primary-color, 10%);
        color: #fff;
      }
    }

    &:hover {
      background-color: #b6b3b3;
      & .item-icon {
        opacity: 1;
      }
    }
  }
}

a:not([href]), a:not([href]):hover {
  //color: inherit;
  text-decoration: none;
}

.welcome{
  color: #333F48;
}

button:disabled{
  opacity: 0.65 !important; 
  //color: red !important;
  cursor: not-allowed !important;
}

.clickable {
  cursor: pointer;
  transition: 100ms ease-out;
  user-select: none;
}

.drag-handle{
  cursor: move;
  display: inline-block;
  flex-direction: column;
  flex-grow: 0;
  padding: 0 .35rem;
  color: #9d9d9d;
}

.competency-list {
  display: flex;
  flex-flow: column;
  width: 100%;
  border: $default-border;
}

.hierarchy-list-item {
  font-size: 15px;
  user-select: none;

  & > .children{
    padding: 0 0 0 .50rem;
  }

  &.in-active {
    color: #a3a3a3;
  }

  &.deleted {
    color: $danger-color;
  }

  & .hierarchy-actions {
    float: right;
  }

  & .hierarchy-item {
    padding: .35rem .75rem .35rem .45rem;
    z-index: 100;

    &.no-children{
      padding-left:23px;
    }

    & .input-group {
      display: inline-block;
      width: 350px;

      .form-control {
        width: 230px;
        display: inline-block;
        padding: 4px 8px;
      }

       .input-group-btn {
         display: inline-block;
       }

      .btn {
        width: 45px;
        display: inline-block;
        padding: 2px 4px 3px 4px;
      }
    }

    & .clickable {
      z-index: 500;
    }

    &.selected{
      background-color: $primary-color;
      color: #fff;

      & i.fa{
        color: #fff !important;
      }

      &:hover{
        background-color: $primary-color;
        color: #fff;
      }
    }

    &:hover {
      background-color: #ebebeb;
    }
  }

  & .competency-list-item {
    padding-left: 1rem;
  }

  & i.fa {
    font-size: 18px;
  }
}

.scroll {
  overflow-y: auto;
  &.scroll-sm {
    max-height: 150px;
  }
  &.scroll-md {
    max-height: 300px;
  }
  &.scroll-lg {
    max-height: 450px;
  }
  &.scroll-xl {
    max-height: 600px;
  }
}


@media (max-width: 767px) {
  .content-wrapper{
    margin-left: 0;
  }

  .content {
    top: $global-nav-height;
  }
}

/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px)
{
    .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, 
    .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
    .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, 
    .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
    .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
    .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
    .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
    {
        display: none !important;
    } 
}


.close-icon {
  font-size: 1.5rem;
  color: #d9534f;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  transition: transform 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.close-icon:hover {
  transform: scale(1.1);
}