﻿@import "variables";

$border-width: 4px;
$nav-link-padding: 20px;

.left-nav-wrapper {
  display: flex;
  position: fixed;
  flex-direction: column;
  border-right: $default-border;
  width: $main-open-nav-width;
  height: 100vh;
  background-color: #fff;
  z-index: 500;

  .logo {
    border-bottom: $default-border;
    padding: $main-nav-padding;
    margin-bottom: 0px;
  }
}

.nav-collapse {
  padding: 8px $nav-link-padding;
  border-bottom: $default-border;

  .btn-nav-collapse {
    color: $success-color !important;
    cursor: pointer;
  }
}

.left-nav {

  .nav {
    margin-top: 0;
  }

  a.nav-link:not([href]) {
    color: $main-nav-link-color;
    cursor: pointer;

    &:hover {
    }
  }

  .active .nav-link{
    background-color: $default-color;
    color: #fff;

    &:hover{
      color: darken(#fff, 15%);
    }
  }

  .nav-link {
    padding-left: $nav-link-padding;
    padding-right: $nav-link-padding;
    font-size: $main-nav-font-size;
    color: $main-nav-link-color;

    &:hover {
      border-left: $border-width solid $success-color;
      padding-left: calc(#{$nav-link-padding} - #{$border-width});
    }

    .fa {
      margin-right: 18px;
      width: 21px;
      height: 21px;
    }
  }

}


.nav-item {
  .menu {
    background-color: $default-bg-color;
    transition: 150ms ease-out;
    .nav-link {
      padding-left: 64px;
      font-size: 14px;

      &:hover {
        border-left: 4px solid $success-color;
        padding-left: calc(64px - 4px);
      }
    }
  }
}

.nav-toggle::after {
  display: inline-block;
  position: relative;
  top: 10px;
  content: "";
  width: 0;
  height: 0;
  margin-left: .4em;
  vertical-align: middle;
  border-top: .4em solid;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
  float: right;
}

$global-dd-width: 400px;
.global-nav {
  position: absolute;
  right: $main-open-nav-width;
  height: 65px;
  display: block;

  & > a {
    list-style: none;
    position: relative;
    display: list-item;
    height: $global-nav-height;
    transition: 200ms ease-out;
    cursor: pointer;
    padding: 6px 20px;
    border-left: $default-border;
    float: left;
    text-align: center;

    &.icon-button {
      padding: 6px 10px;
    }

    &.icon-button > i.fa {
      font-size: 36px;
      color: $danger-color;
    }
  }

  & > a.dd-toggle {
    width: $global-dd-width;
    text-align: left;
  }

  &.show > a {
    background-color: $default-bg-color;
  }

  & > a:hover {
    background-color: $default-bg-color;
    text-decoration: none;
  }

  .dropdown-menu {
    background-color: $default-bg-color;
    width: $global-dd-width;
    border-radius: 0;
    top: calc(#{$global-nav-height} - 1px);
    border: none;
    box-shadow: 2px 5px 5px 0px rgba(0,0,0,0.2);

    li {
      font-size: 14px;
      a {
        padding: 8px 20px;
        display: block;
        cursor: pointer;
      }
      a:hover {
        background: #fafafa;
        text-decoration: none;

      }
    }
  }

  .global-nav-icon {
    float: right;
    width: 38px;
    height: $global-nav-height;
    display: inline-block;
    vertical-align: middle;
    font-size: 38px;
    color: #7F9C90;
  }

  .impersonate {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    font-style: italic;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-right: 42px;
    white-space: nowrap;
    color:  #7F9C90;
  }

  .welcome{
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-right: 42px;
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .left-nav-wrapper {
    display: none;
    visibility: hidden;
  }

  .logo {
    border-bottom: none;
    padding: 7px;
    margin-bottom: 0px;
    float: left;
    height: 64px;
    display: block;

    & img {
      height: 100%;
    }
  }

  .global-nav {
    margin-right: auto;
    & > a {
      width: auto;
      padding: 13px 20px;
    }

    & .global-nav-icon {
      height: auto;
      float: none;
    }

    & .dropdown-menu {
      position: fixed;
      left: 0;
      width: 100%;
      border-bottom: 4px rgba(0, 0, 0, .15) solid;
    }
  }
}

